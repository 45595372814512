.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

.s-header {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0 10px 0;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 7;
}

.s-header__mob {
  display: none;
  position: absolute;
  right: 15px;
  top: 0;
  padding: 5px 0 20px 20px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__mob:hover {
  color: #d32231;
  text-decoration: none;
}

.s-header__logo {
  display: block;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__logo img,
.s-header__logo svg {
  max-width: 100%;
}

.s-header__logo:hover {
  color: #d32231;
  text-decoration: none;
}

.s-header__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 10px 0 20px 0;
}

.s-header__info {
  display: inline-flex;
  align-items: center;
  position: relative;
  max-width: 180px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.s-header__info-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 14px;
  border-radius: 999rem;
  background-color: #f5f5f5;
}

.s-header__info-image img,
.s-header__info-image svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
}

.s-header__info a {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__info a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-header__button {
  display: inline-block;
  position: relative;
  margin-left: 30px;
  padding: 6px 11px;
  border-radius: 5px;
  border: 1px solid #d32231;
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__button:hover {
  background: #d32231;
  color: #ffffff;
  text-decoration: none;
}

.s-header__lang {
  display: inline-block;
  margin: 0 0 0 8px;
}

.s-header__lang input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.s-header__lang input:checked ~ div {
  color: #d32231;
}

.s-header__lang > div {
  display: block;
  color: #919191;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__lang > div:hover {
  color: #d32231;
  text-decoration: none;
}

.s-header__nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.s-header__nav ul {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.s-header__nav li:hover > ul {
  display: block;
}

.s-header__nav > li {
  position: relative;
}

.s-header__nav > li:hover > a {
  border-color: #d32231;
}

.s-header__nav > li > a {
  display: inline-block;
  position: relative;
  margin: 0 100px 0 0;
  padding: 6px 0 2px 0;
  border-bottom: 3px solid transparent;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__nav > li > a:hover {
  border-color: #d32231;
  color: #000000;
  text-decoration: none;
}

.s-header__nav > li > ul {
  position: absolute;
  top: 100%;
  left: -28px;
  width: 340px;
  padding: 20px 0;
  border-radius: 5px;
  background-color: #373737;
  z-index: 8;
}

.s-header__nav > li > ul > li {
  display: block;
}

.s-header__nav > li > ul > li:hover > a {
  background: #212121;
}

.s-header__nav > li > ul > li > a {
  display: block;
  position: relative;
  padding: 10px 28px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__nav > li > ul > li > a:hover {
  background-color: #212121;
  color: #ffffff;
  text-decoration: none;
}

.s-header__nav > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0;
  width: 340px;
  padding: 20px 0;
  border-radius: 5px;
  background-color: #212121;
  z-index: 9;
}

.s-header__nav > li > ul > li > ul li {
  display: block;
  position: relative;
}

.s-header__nav > li > ul > li > ul li a {
  display: block;
  position: relative;
  padding: 12px 28px 12px 40px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__nav > li > ul > li > ul li a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-header__search {
  display: block;
  position: relative;
}

.s-header__search input {
  width: 100%;
  max-width: 205px;
  padding: 6px 30px 6px 10px;
  border: 0;
  outline: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}

.s-header__search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-header__search-button img,
.s-header__search-button svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
}

.s-header__search-button:hover {
  opacity: 0.8;
  text-decoration: none;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

@keyframes reverseRotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

.s-home-top {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 130px 0 10px 0;
  background: #ffffff;
}

.s-home-top__title {
  display: block;
  max-width: 620px;
  margin: 40px 0 0 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-home-top__text {
  display: block;
  max-width: 410px;
  margin: 40px 0 0 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-home-top__button {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 75px 0 0 0;
  padding: 16px 40px 16px 45px;
  border-radius: 5px;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-top__button img,
.s-home-top__button svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  filter: brightness(0) invert(1);
}

.s-home-top__button:hover {
  background-color: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-home-top__image {
  display: block;
  position: relative;
  transform-style: preserve-3d;
}

.s-home-top__image img,
.s-home-top__image svg {
  position: relative;
  z-index: 2;
}

.s-home-top__1 {
  display: block;
  position: absolute;
  top: -24px;
  left: 20px;
  width: 507px;
  height: 509px;
  background: url("../images/circle_1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 10s linear infinite;
}

.s-home-top__2 {
  display: block;
  position: absolute;
  top: -34px;
  left: 75px;
  width: 399px;
  height: 529px;
  background: url("../images/circle_2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: reverseRotateAnimation 15s linear infinite;
}

.s-home-top__3 {
  display: block;
  position: absolute;
  top: -5px;
  left: 20px;
  width: 467px;
  height: 471px;
  background: url("../images/circle_3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 20s linear infinite;
}

.s-home-gray {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

.s-home-cards {
  display: block;
  position: relative;
  padding: 30px 0;
}

.s-home-cards-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 570px;
  overflow: hidden;
  margin: 0 auto 30px auto;
  border-radius: 5px;
  background-color: #ffffff;
  background-position: right bottom;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-cards-card:after {
  display: block;
  position: absolute;
  content: "";
  left: -260px;
  bottom: 0;
  width: 260px;
  height: 4px;
  background-color: #d32231;
  z-index: 1;
  transition: all 0.2s ease-out;
}

.s-home-cards-card__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 270px;
  padding: 45px 40px 35px 40px;
}

.s-home-cards-card__title {
  display: block;
  max-width: 275px;
  margin: 0;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.s-home-cards-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
}

.s-home-cards-card__link img,
.s-home-cards-card__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  transition: all 0.2s ease-out;
}

.s-home-cards-card:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.s-home-cards-card:hover:after {
  left: 0;
  width: 100%;
}

.s-home-cards-card:hover .s-home-cards-card__link img,
.s-home-cards-card:hover .s-home-cards-card__link svg {
  transform: translateX(5px);
}

.s-home-form {
  display: block;
  position: relative;
  padding: 20px 0;
}

.s-home-form__title {
  display: block;
  margin: 0 0 10px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-form__text {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-home-form input {
  display: block;
  width: 100%;
  max-width: 420px;
  margin: 0 0 30px 0;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  outline: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.s-home-form input:focus {
  border-color: #d32231;
}

.s-home-form__info {
  display: block;
  margin: 26px 0 0 0;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-home-form__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0;
  padding: 16px;
  border: 0;
  outline: none;
  border-radius: 5px;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-form__button img,
.s-home-form__button svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  filter: brightness(0) invert(1);
}

.s-home-form__button:hover {
  background: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.s-list img,
.s-list svg {
  display: block;
  max-width: 50px;
  max-height: 50px;
}

.s-list__title {
  display: block;
  margin: 26px 0 30px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-list__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-list__link img,
.s-list__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  transition: all 0.2s ease-out;
}

.s-list__link:hover {
  /* opacity: 0.7; */
  color: #d32231;
  text-decoration: none;
}

.s-list__link:hover img,
.s-list__link:hover svg {
  transform: translateX(5px);
}

.s-home-what {
  display: block;
  position: relative;
  margin-top: -120px;
  pointer-events: none;
}

.s-home-what__title {
  display: block;
  margin: 320px 0 0 0;
  color: #000000;
  font-size: 80px;
  font-weight: 200;
  text-transform: uppercase;
}

.s-home-what__image {
  display: block;
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
}

.s-home-what__image img,
.s-home-what__image svg {
  display: block;
  position: relative;
  max-width: 345px;
  margin: 0 auto;
  z-index: 2;
}

.s-home-what__1 {
  display: block;
  position: absolute;
  top: -164px;
  left: -30px;
  width: 657px;
  height: 658px;
  background: url("../images/circle_1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 10s linear infinite;
}

.s-home-what__2 {
  display: block;
  position: absolute;
  top: -174px;
  left: 25px;
  width: 515px;
  height: 684px;
  background: url("../images/circle_2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: reverseRotateAnimation 15s linear infinite;
}

.s-home-what__3 {
  display: block;
  position: absolute;
  top: -135px;
  left: -30px;
  width: 605px;
  height: 608px;
  background: url("../images/circle_3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 20s linear infinite;
}

.s-home-about {
  display: block;
  position: relative;
  padding: 80px 0 50px 0;
}

.s-home-about__title {
  display: block;
  margin: 0 0 35px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-about__content {
  display: block;
  position: relative;
  max-width: 630px;
}

.s-home-about__content p {
  display: block;
  margin: 0 0 25px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-home-about-card {
  display: block;
  position: relative;
  margin: 0;
  padding: 35px 0 45px 0;
}

.s-home-about-card__number {
  display: block;
  margin: 0 0 5px 0;
  color: #d32231;
  font-size: 70px;
  font-weight: 200;
}

.s-home-about-card__text {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}

.s-home-wins {
  display: block;
  position: relative;
  padding: 35px 0;
  background-color: #d32231;
}

.s-home-wins-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 310px;
  min-height: 130px;
  margin: 0 auto;
  padding: 2px 35px;
  background: url("../images/wins_bg.png") no-repeat center center;
  background-size: contain;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  transform-style: preserve-3d;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-wins-card:after {
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 130px;
  height: 130px;
  margin-top: -65px;
  margin-left: -65px;
  border-radius: 999rem;
  background-color: #ba1523;
  transform: translateZ(-1px) scale(0);
  transition: all 0.2s ease-out;
}

.s-home-wins-card:hover {
  color: #ffffff;
  text-decoration: none;
}

.s-home-wins-card:hover:after {
  transform: translateZ(-1px) scale(1);
}

.s-home-where {
  display: block;
  position: relative;
  padding: 100px 0;
  background-color: #ffffff;
}

.s-home-where__title {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-where-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 80px 0 0 0;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}

.s-home-where-card img,
.s-home-where-card svg {
  display: block;
  max-width: 55px;
  max-height: 55px;
}

.s-home-projects {
  display: block;
  position: relative;
  padding: 90px 0;
  background-color: #f5f5f5;
}

.s-home-projects__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
}

.s-home-projects__title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-projects__link {
  display: inline-block;
  margin: 0 0 0 35px;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.s-home-projects__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-home-projects__cards {
  display: block;
  position: relative;
  width: 100%;
}

.s-home-projects-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 570px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease-out;
}

.s-home-projects-card__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 350px;
  padding: 40px 40px 35px 40px;
  border-radius: 5px;
  background-size: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background-position: 0%;
  transition: all 0.2s ease-out;
}

.s-home-projects-card__what {
  display: block;
  margin: 0 0 10px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.s-home-projects-card__title {
  display: block;
  max-width: 330px;
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.s-home-projects-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-projects-card__link img,
.s-home-projects-card__link svg {
  display: block;
  max-width: 15px;
  max-height: 15px;
  margin-left: 20px;
  transition: all 0.5s ease-out;
}

.s-home-projects-card__link:hover {
  opacity: 0.7;
  color: #d32231;
  text-decoration: none;
}

.s-home-projects-card:hover {
  background-size: 105% auto;
  text-decoration: none;
}

.s-home-projects-card:hover .s-home-projects-card__link img,
.s-home-projects-card:hover .s-home-projects-card__link svg {
  transform: translateX(5px);
}

.s-home-projects-card:hover .s-home-projects-card__content {
  /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%); */
  background-position: 0%;
  background-size: 300%;
}

.s-home-projects .swiper-button-next,
.s-home-projects .swiper-button-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 999rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-projects .swiper-button-next img,
.s-home-projects .swiper-button-next svg,
.s-home-projects .swiper-button-prev img,
.s-home-projects .swiper-button-prev svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.2s ease-out;
}

.s-home-projects .swiper-button-next:before, .s-home-projects .swiper-button-next:after,
.s-home-projects .swiper-button-prev:before,
.s-home-projects .swiper-button-prev:after {
  display: none;
}

.s-home-projects .swiper-button-next:hover,
.s-home-projects .swiper-button-prev:hover {
  background: #d32231;
  text-decoration: none;
}

.s-home-projects .swiper-button-next:hover img,
.s-home-projects .swiper-button-next:hover svg,
.s-home-projects .swiper-button-prev:hover img,
.s-home-projects .swiper-button-prev:hover svg {
  filter: brightness(0) invert(1);
}

.s-home-projects .swiper-button-next {
  margin-left: 20px;
}

.s-home-map {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0 75px 0;
  background-color: #f5f5f5;
}

.s-home-map__title {
  display: block;
  position: relative;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-map__map {
  display: block;
  position: relative;
  max-width: 100%;
  margin: -40px auto 0 auto;
}

.s-home-news {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 90px 0;
}

.s-home-news__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
}

.s-home-news__title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-news__link {
  display: inline-block;
  margin: 0 0 0 35px;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.s-home-news__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-home-news__cards {
  display: block;
  position: relative;
  width: 100%;
}

.s-home-news-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 270px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-news-card__image {
  display: block;
  position: relative;
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #878787;
}

.s-home-news-card__image:after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: -100%;
  width: 128px;
  height: 100%;
  background: url("../images/news_hover.png") no-repeat left center;
  background-size: auto 100%;
  transition: all 0.4s ease-out;
}

.s-home-news-card__title {
  display: block;
  height: 95px;
  overflow: hidden;
  margin: 15px 0 10px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.s-home-news-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
}

.s-home-news-card__link img,
.s-home-news-card__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}

.s-home-news-card:hover {
  text-decoration: none;
}

.s-home-news-card:hover .s-home-projects-card__link img,
.s-home-news-card:hover .s-home-projects-card__link svg {
  transform: translateX(5px);
}

.s-home-news-card:hover .s-home-projects-card__content {
  background: rgba(0, 0, 0, 0.7);
}

.s-home-news-card:hover .s-home-news-card__image:after {
  left: 0;
}

.s-home-news .swiper-button-next,
.s-home-news .swiper-button-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 999rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-news .swiper-button-next img,
.s-home-news .swiper-button-next svg,
.s-home-news .swiper-button-prev img,
.s-home-news .swiper-button-prev svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.2s ease-out;
}

.s-home-news .swiper-button-next:before, .s-home-news .swiper-button-next:after,
.s-home-news .swiper-button-prev:before,
.s-home-news .swiper-button-prev:after {
  display: none;
}

.s-home-news .swiper-button-next:hover,
.s-home-news .swiper-button-prev:hover {
  background: #d32231;
  text-decoration: none;
}

.s-home-news .swiper-button-next:hover img,
.s-home-news .swiper-button-next:hover svg,
.s-home-news .swiper-button-prev:hover img,
.s-home-news .swiper-button-prev:hover svg {
  filter: brightness(0) invert(1);
}

.s-home-news .swiper-button-next {
  margin-left: 20px;
}

.s-home-news__image {
  display: block;
  position: relative;
  left: -300px;
  bottom: -300px;
  width: 100%;
  transform-style: preserve-3d;
}

.s-home-news__image img,
.s-home-news__image svg {
  display: block;
  position: relative;
  max-width: 345px;
  margin: 0 auto;
  z-index: 2;
}

.s-home-news__image_page {
  position: absolute;
  left: auto;
  bottom: 30px;
  left: 85%;
}

.s-home-news__image_article {
  position: absolute;
  bottom: -20px;
  left: calc(50% - 300px);
}

.s-home-news__1 {
  display: block;
  position: absolute;
  top: -164px;
  left: -30px;
  width: 657px;
  height: 658px;
  background: url("../images/circle_1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 10s linear infinite;
}

.s-home-news__2 {
  display: block;
  position: absolute;
  top: -174px;
  left: 25px;
  width: 515px;
  height: 684px;
  background: url("../images/circle_2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: reverseRotateAnimation 15s linear infinite;
}

.s-home-news__3 {
  display: block;
  position: absolute;
  top: -135px;
  left: -30px;
  width: 605px;
  height: 608px;
  background: url("../images/circle_3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 20s linear infinite;
}

.s-smi {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0 80px 0;
}

.s-smi__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
}

.s-smi__title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-smi__link {
  display: inline-block;
  margin: 0 0 0 35px;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.s-smi__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-smi__cards {
  display: block;
  position: relative;
  width: 100%;
}

.s-smi-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 270px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-smi-card__image {
  display: block;
  position: relative;
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #878787;
}

.s-smi-card__image:after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: -100%;
  width: 128px;
  height: 100%;
  background: url("../images/news_hover.png") no-repeat left center;
  background-size: auto 100%;
  transition: all 0.4s ease-out;
}

.s-smi-card__title {
  display: block;
  height: 95px;
  overflow: hidden;
  margin: 15px 0 10px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.s-smi-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
}

.s-smi-card__link img,
.s-smi-card__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}

.s-smi-card:hover {
  text-decoration: none;
}

.s-smi-card:hover .s-smi-card__link img,
.s-smi-card:hover .s-smi-card__link svg {
  transform: translateX(5px);
}

.s-smi-card:hover .s-smi-card__content {
  background: rgba(0, 0, 0, 0.7);
}

.s-smi-card:hover .s-smi-card__image:after {
  left: 0;
}

.s-smi .swiper-button-next,
.s-smi .swiper-button-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 999rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-smi .swiper-button-next img,
.s-smi .swiper-button-next svg,
.s-smi .swiper-button-prev img,
.s-smi .swiper-button-prev svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.2s ease-out;
}

.s-smi .swiper-button-next:before, .s-smi .swiper-button-next:after,
.s-smi .swiper-button-prev:before,
.s-smi .swiper-button-prev:after {
  display: none;
}

.s-smi .swiper-button-next:hover,
.s-smi .swiper-button-prev:hover {
  background: #d32231;
  text-decoration: none;
}

.s-smi .swiper-button-next:hover img,
.s-smi .swiper-button-next:hover svg,
.s-smi .swiper-button-prev:hover img,
.s-smi .swiper-button-prev:hover svg {
  filter: brightness(0) invert(1);
}

.s-smi .swiper-button-next {
  margin-left: 20px;
}

.s-smi__image {
  display: block;
  position: relative;
  left: -300px;
  bottom: -300px;
  width: 100%;
  transform-style: preserve-3d;
}

.s-smi__image img,
.s-smi__image svg {
  display: block;
  position: relative;
  max-width: 345px;
  margin: 0 auto;
  z-index: 2;
}

.s-home-seo {
  display: block;
  position: relative;
  padding: 90px 0 40px 0;
  background-color: #f5f5f5;
}

.s-home-seo_hide {
  max-height: 100% !important;
}

.s-home-seo__title {
  display: block;
  margin: 0 0 36px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-home-seo__content {
  display: block;
  position: relative;
  max-height: 96px;
  overflow: hidden;
}

.s-home-seo__content p {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-home-seo__more {
  display: flex;
  align-items: center;
  position: relative;
  margin: 25px 0 0 0;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-home-seo__more img,
.s-home-seo__more svg {
  display: block;
  max-width: 14px;
  max-height: 14px;
  margin-left: 14px;
  transition: all 0.2s ease-out;
}

.s-home-seo__more_active img,
.s-home-seo__more_active svg {
  transform: rotate(180deg);
}

.s-home-seo__more:hover {
  color: #d32231;
  text-decoration: none;
}

.s-footer {
  display: block;
  position: relative;
  width: 100%;
  padding: 100px 0 50px 0;
  background-color: #f5f5f5;
}

.s-footer__logo {
  display: block;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__logo img,
.s-footer__logo svg {
  max-width: 100%;
}

.s-footer__logo:hover {
  color: #d32231;
  text-decoration: none;
}

.s-footer__what {
  display: block;
  margin: 30px 0 25px 0;
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 400;
}

.s-footer__button {
  display: inline-block;
  position: relative;
  padding: 6px 11px;
  border-radius: 5px;
  border: 1px solid #d32231;
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__button:hover {
  background: #d32231;
  color: #ffffff;
  text-decoration: none;
}

.s-footer__phone {
  display: block;
  margin: 0 0 15px 0;
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__phone:hover {
  color: #d32231;
  text-decoration: none;
}

.s-footer__link {
  display: flex;
  align-items: center;
  margin: 74px 0 0 0;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__link img,
.s-footer__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}

.s-footer__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-footer__link:hover img,
.s-footer__link:hover svg {
  transform: translateX(5px);
}

.s-footer__subtitle {
  display: block;
  margin: 0 0 20px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__subtitle:hover {
  color: #d32231;
  text-decoration: none;
}

.s-footer__nav {
  display: block;
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;
}

.s-footer__nav li {
  display: block;
}

.s-footer__nav li a {
  display: block;
  margin: 0 0 11px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-footer__nav li a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
}

.s-modal__centered {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.s-modal__close {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-modal__close:hover, .s-modal__close:active, .s-modal__close:focus {
  opacity: 0.8;
  text-decoration: none;
}

.s-modal__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 630px;
  margin: 20px 0;
  padding: 20px;
  background: transparent;
}

.s-modal__content_page {
  align-items: flex-start;
  max-width: 100%;
  padding: 0;
}

.s-modal__content_page .s-modal__button {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 15px;
}

.s-modal__content_page .s-modal__title {
  text-align: left;
}

.s-modal__content_page .s-modal__text {
  text-align: left;
}

.s-modal__content_page .s-modal__info {
  margin: 0;
  font-size: 12px;
  text-align: left;
}

.s-modal__content_page .s-modal__file > span {
  background-position: 0 center;
}

.s-modal__content_page input {
  margin-bottom: 22px;
}

.s-modal__title {
  display: block;
  margin: 0 0 10px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.s-modal__text {
  display: block;
  margin: 0 0 45px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: center;
}

.s-modal__input {
  display: block;
  width: 100%;
  margin: 0 0 30px 0;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  outline: none;
  background-color: #f5f5f5;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.s-modal__input:focus {
  border-color: #d32231;
}

.s-modal__file {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 35px 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-modal__file input {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0;
}

.s-modal__file > span {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 25px;
  padding-left: 47px;
  background: url("../images/icon_file_upload.png") no-repeat left center;
  background-size: contain;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
}

.s-modal__file:hover {
  opacity: 0.8;
  text-decoration: none;
}

.s-modal__info {
  display: block;
  margin: 15px 0 0 0;
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.s-modal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 16px 64px;
  border: 0;
  outline: none;
  border-radius: 5px;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-modal__button img,
.s-modal__button svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  filter: brightness(0) invert(1);
}

.s-modal__button:hover {
  background: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-breadcrumps {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 30px 0;
  list-style: none;
}

.s-breadcrumps li {
  display: inline-block;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
}

.s-breadcrumps li img,
.s-breadcrumps li svg {
  display: inline-block;
  margin: 0 10px;
  max-width: 6px;
}

.s-breadcrumps li a {
  color: #8d8d8d;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-breadcrumps li a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-press {
  display: block;
  position: relative;
  padding: 0 0 30px 0;
}

.s-press__title {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-press__cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  width: 100%;
}

.s-press-card {
  display: inline-block;
  margin: 0 20px 20px 0;
  padding: 12px 26px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-press-card:hover {
  background-color: #d32231;
  color: #ffffff;
  text-decoration: none;
}

.s-about {
  display: block;
  position: relative;
  padding: 0;
}

.s-about__title {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-about__name {
  display: block;
  margin: 0 0 5px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-about__who {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.s-about__content {
  display: block;
  max-width: 580px;
}

.s-about__content p {
  display: block;
  margin: 30px 0 0 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-about__image {
  display: block;
  position: relative;
  transform-style: preserve-3d;
}

.s-about__image img,
.s-about__image svg {
  position: relative;
  max-width: 100%;
  z-index: 2;
}

.s-about__1 {
  display: block;
  position: absolute;
  top: -30px;
  left: -30px;
  width: 485px;
  height: 485px;
  background: url("../images/circle_1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  /* animation: rotateAnimation 10s linear infinite; */
}

.s-about__2 {
  display: block;
  position: absolute;
  top: -40px;
  left: 20px;
  width: 381px;
  height: 505px;
  background: url("../images/circle_2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  /* animation: reverseRotateAnimation 15s linear infinite; */
}

.s-about__3 {
  display: block;
  position: absolute;
  top: -15px;
  left: -15px;
  width: 447px;
  height: 449px;
  background: url("../images/circle_3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  /* animation: rotateAnimation 20s linear infinite; */
}

.s-wow {
  display: block;
  position: relative;
  padding: 55px 0 20px 0;
}

.s-wow__title {
  display: block;
  margin: 0 0 45px 0;
  color: #d32231;
  font-size: 30px;
  font-weight: 400;
}

.s-wow__image {
  display: block;
  max-width: 100%;
}

.s-wow__text {
  display: block;
  margin: 120px 0 0 0;
  color: #000000;
  font-size: 80px;
  font-weight: 200;
  text-transform: uppercase;
}

.s-history {
  display: block;
  position: relative;
  width: 100%;
  padding: 100px 0;
}

.s-history__wrap {
  display: block;
  position: relative;
  width: 100%;
  max-width: 980px;
  overflow: hidden;
  background: url("../images/line_red.png") no-repeat center bottom;
  background-size: 100% 2px;
}

.s-history__wrap:before {
  display: block;
  position: absolute;
  content: "";
  width: 75px;
  height: 100%;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to left, rgba(245, 245, 245, 0), whitesmoke);
  z-index: 4;
  pointer-events: none;
}

.s-history__wrap:after {
  display: block;
  position: absolute;
  content: "";
  width: 75px;
  height: 100%;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, rgba(245, 245, 245, 0), whitesmoke);
  z-index: 4;
  pointer-events: none;
}

.s-history__title {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-history__thumbs {
  display: block;
  position: relative;
  width: 100%;
  max-width: 530px;
  overflow: visible !important;
  margin: 0 0 0 80px;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #d32231;
}

.s-history__thumbs .swiper-wrapper {
  display: flex;
  align-items: flex-end;
}

.s-history__thumbs .swiper-slide-active .s-history-thumb {
  max-width: 500px;
}

.s-history__thumbs .swiper-slide-active .s-history-thumb__date {
  font-size: 70px;
  font-weight: 200;
}

.s-history__thumbs .swiper-slide-active .s-history-thumb__title {
  max-width: 175px;
}

.s-history__top {
  display: block;
  position: relative;
  width: 100%;
  padding: 55px 0 0 0;
}

.s-history-thumb {
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  width: auto;
  max-width: 180px;
}

.s-history-thumb__date {
  display: block;
  color: #d32231;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
}

.s-history-thumb__title {
  display: block;
  max-width: 0;
  overflow: hidden;
  margin: 0 0 0 28px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.s-history-thumb:before {
  display: block;
  position: absolute;
  content: "";
  left: -20px;
  bottom: -10px;
  width: 2px;
  height: 10px;
  background-color: #d32231;
}

.s-history-card {
  display: block;
  position: relative;
  max-width: 100%;
}

.s-history-card__image {
  display: block;
  width: 100%;
  max-width: 570px;
  height: 350px;
  background-position: center center;
  background-size: cover;
}

.s-history-card__text {
  display: block;
  max-height: 350px;
  overflow: hidden;
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-history .swiper-button-next,
.s-history .swiper-button-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 110px;
  right: 0;
  bottom: auto;
  left: auto;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 999rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-history .swiper-button-next img,
.s-history .swiper-button-next svg,
.s-history .swiper-button-prev img,
.s-history .swiper-button-prev svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.2s ease-out;
}

.s-history .swiper-button-next:before, .s-history .swiper-button-next:after,
.s-history .swiper-button-prev:before,
.s-history .swiper-button-prev:after {
  display: none;
}

.s-history .swiper-button-next:hover,
.s-history .swiper-button-prev:hover {
  background: #d32231;
  text-decoration: none;
}

.s-history .swiper-button-next:hover img,
.s-history .swiper-button-next:hover svg,
.s-history .swiper-button-prev:hover img,
.s-history .swiper-button-prev:hover svg {
  filter: brightness(0) invert(1);
}

.s-history .swiper-button-prev {
  right: 70px;
}

.s-features {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 100px 0 50px 0;
  background-color: #1f1f1f;
}

.s-features__image {
  display: block;
  position: relative;
  margin-left: -380px;
}

.s-features__title {
  display: block;
  margin: 0 0 60px 0;
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
}

.s-features-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: 0 0 50px 0;
}

.s-features-card img,
.s-features-card svg {
  display: block;
  max-width: 100%;
}

.s-features-card__title {
  display: block;
  position: relative;
  margin: 0 0 0 -20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.s-papers {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
  background: #f5f5f5;
}

.s-papers__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
}

.s-papers__title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-papers__link {
  display: inline-block;
  margin: 0 0 0 35px;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.s-papers__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-papers__cards {
  display: block;
  position: relative;
  width: 100%;
}

.s-papers-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 170px;
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-papers-card__image {
  display: block;
  width: 100%;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}

.s-papers-card__content {
  display: block;
  width: 100%;
  height: 235px;
  border-radius: 5px;
  background-color: rgba(211, 34, 49, 0.8);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.s-papers-card:hover {
  text-decoration: none;
}

.s-papers-card:hover .s-papers-card__content {
  opacity: 1;
}

.s-papers .swiper-button-next,
.s-papers .swiper-button-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 999rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-papers .swiper-button-next img,
.s-papers .swiper-button-next svg,
.s-papers .swiper-button-prev img,
.s-papers .swiper-button-prev svg {
  display: block;
  max-width: 20px;
  max-height: 20px;
  transition: all 0.2s ease-out;
}

.s-papers .swiper-button-next:before, .s-papers .swiper-button-next:after,
.s-papers .swiper-button-prev:before,
.s-papers .swiper-button-prev:after {
  display: none;
}

.s-papers .swiper-button-next:hover,
.s-papers .swiper-button-prev:hover {
  background: #d32231;
  text-decoration: none;
}

.s-papers .swiper-button-next:hover img,
.s-papers .swiper-button-next:hover svg,
.s-papers .swiper-button-prev:hover img,
.s-papers .swiper-button-prev:hover svg {
  filter: brightness(0) invert(1);
}

.s-papers .swiper-button-next {
  margin-left: 20px;
}

.s-good {
  display: block;
  position: relative;
  width: 100%;
}

.s-good__content {
  display: block;
  position: relative;
  min-height: 402px;
}

.s-good__content p {
  display: block;
  margin: 30px 0 0 0;
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-good__title {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 35px;
  font-weight: 700;
}

.s-good__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.s-good__button {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0 30px 0 0;
  padding: 15px 26px;
  border-radius: 5px;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-good__button img,
.s-good__button svg {
  display: block;
  max-width: 8px;
  max-height: 13px;
  margin-left: 12px;
  filter: brightness(0) invert(1);
}

.s-good__button:hover {
  background-color: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-good__button_alt {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #d32231;
  outline: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-good__button_alt img,
.s-good__button_alt svg {
  display: block;
  max-width: 26px;
  max-height: 26px;
  margin-right: 20px;
}

.s-good__button_alt:hover {
  border-color: #ff6774;
  background-color: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-good__button_alt:hover img,
.s-good__button_alt:hover svg {
  filter: brightness(0) invert(1);
}

.gallery-top {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  background: #ffffff;
}

.gallery-top .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
  padding: 10px;
}

.gallery-top .swiper-slide img,
.gallery-top .swiper-slide svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.gallery-thumbs {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 0 10px 10px 10px;
  background: #ffffff;
}

.gallery-thumbs .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.gallery-thumbs .swiper-slide img,
.gallery-thumbs .swiper-slide svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.gallery-thumbs .swiper-slide:hover {
  color: #ffffff;
  text-decoration: none;
}

.gallery-thumbs .swiper-slide-thumb-active {
  border-color: #d32231 !important;
}

.s-tabs {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0;
}

.s-tabs__header {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 3;
}

.s-tabs__header li {
  display: inline-block;
  margin-right: 45px;
  margin-bottom: -4px;
  padding: 5px 0;
  border-bottom: 4px solid transparent;
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-tabs__header li.current {
  border-color: #d32231;
  color: #000000;
  font-weight: 600;
}

.s-tabs__header li:hover {
  text-decoration: none;
}

.s-tabs__title {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-tabs__content {
  display: none;
  position: relative;
  width: 100%;
  min-height: 504px;
  margin: 0;
  padding: 80px;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.s-tabs__content p {
  margin: 20px 0 0 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-tabs__content.current {
  display: block;
}

.s-files {
  display: block;
  position: relative;
  width: 100%;
  padding: 30px 0;
}

.s-files__title {
  display: block;
  margin: 0 0 36px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-files-card {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-files-card__image {
  display: block;
  max-width: 24px;
  max-height: 30px;
  margin: 0 24px 0 0;
  filter: brightness(0);
  opacity: 0.2;
  transition: all 0.2s ease-out;
}

.s-files-card__title {
  display: block;
  flex: 1 1 auto;
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  transition: all 0.2s ease-out;
}

.s-files-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease-out;
}

.s-files-card__link img,
.s-files-card__link svg {
  display: block;
  max-width: 8px;
  max-height: 13px;
  margin-left: 9px;
  transition: all 0.2s ease-out;
}

.s-files-card:hover {
  text-decoration: none;
}

.s-files-card:hover .s-files-card__link img,
.s-files-card:hover .s-files-card__link svg {
  transform: translateX(5px);
}

.s-files-card:hover .s-files-card__title {
  color: #d32231;
}

.s-files-card:hover .s-files-card__image {
  opacity: 1;
  filter: none;
}

.s-goods {
  display: block;
  position: relative;
  width: 100%;
}

.s-goods__title {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-goods-aside {
  display: block;
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.s-goods-aside li {
  display: block;
}

.s-goods-aside_active .s-goods-aside-card {
  border-color: #d32231;
  color: #000000;
  text-decoration: none;
}

.s-goods-aside_active .s-goods-aside-card img,
.s-goods-aside_active .s-goods-aside-card svg {
  filter: none;
  opacity: 1;
}

.s-goods-aside-card {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 20px 28px;
  min-height: 70px;
  border-bottom: 4px solid transparent;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-goods-aside-card img,
.s-goods-aside-card svg {
  display: block;
  max-width: 32px;
  max-height: 32px;
  margin: 0 auto;
  filter: brightness(0);
  opacity: 0.2;
  transition: all 0.2s ease-out;
}

.s-goods-aside-card:hover {
  border-color: #d32231;
  color: #000000;
  text-decoration: none;
}

.s-goods-aside-card:hover img,
.s-goods-aside-card:hover svg {
  filter: none;
  opacity: 1;
}

.s-goods-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 770px;
  overflow: hidden;
  margin: 0 auto 30px auto;
  padding: 40px 40px 35px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-goods-card:after {
  display: block;
  position: absolute;
  content: "";
  left: -100%;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #d32231;
  z-index: 1;
  transition: all 0.2s ease-out;
}

.s-goods-card img,
.s-goods-card svg {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.s-goods-card__content {
  display: block;
  position: relative;
  width: 100%;
  min-height: 174px;
}

.s-goods-card__content p {
  display: block;
  margin: 10px 0 0 0;
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 400;
}

.s-goods-card__title {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.s-goods-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
}

.s-goods-card__link img,
.s-goods-card__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  transition: all 0.2s ease-out;
}

.s-goods-card:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  text-decoration: none;
}

.s-goods-card:hover .s-goods-card__link img,
.s-goods-card:hover .s-goods-card__link svg {
  transform: translateX(5px);
}

.s-goods-card:hover:after {
  left: 0;
}

.s-page-form {
  display: block;
  position: relative;
  padding: 80px 0;
}

.s-page-form__title {
  display: block;
  margin: 0 0 10px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-page-form__text {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-page-form input {
  display: block;
  width: 100%;
  margin: 0 0 23px 0;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  outline: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.s-page-form input:focus {
  border-color: #d32231;
}

.s-page-form__info {
  display: block;
  margin: 0;
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-page-form__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0;
  padding: 16px;
  border: 0;
  outline: none;
  border-radius: 5px;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-page-form__button img,
.s-page-form__button svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  filter: brightness(0) invert(1);
}

.s-page-form__button:hover {
  background: #ff6774;
  color: #ffffff;
  text-decoration: none;
}

.s-get {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
}

.s-get-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.s-get-card img,
.s-get-card svg {
  display: block;
  max-width: 50px;
  max-height: 50px;
}

.s-get-card__title {
  display: block;
  margin: 26px 0 30px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-get-card__link {
  display: inline-flex;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-get-card__link img,
.s-get-card__link svg {
  display: block;
  max-width: 13px;
  max-height: 13px;
  margin-left: 12px;
  transition: all 0.2s ease-out;
}

.s-get-card__link:hover {
  opacity: 0.7;
  color: #d32231;
  text-decoration: none;
}

.s-get-card__link:hover .s-get-card__link img,
.s-get-card__link:hover .s-get-card__link svg {
  transform: translateX(5px);
}

.s-article {
  display: block;
  position: relative;
  width: 100%;
}

.s-article__title {
  display: block;
  margin: 0 0 25px 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-article__big {
  display: block;
  width: 100%;
  height: 560px;
  border-radius: 5px 5px 0 0;
  background-position: center center;
  background-size: cover;
}

.s-article__info {
  display: block;
  margin: 0 0 55px 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-article-gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
}

.s-article-gallery-card {
  display: block;
  position: relative;
  width: 100%;
  max-width: 170px;
  height: 170px;
  margin: 0 22px 22px 0;
  border-radius: 5px;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-article-gallery-card:after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  background-color: rgba(211, 34, 49, 0.8);
  transition: all 0.2s ease-out;
}

.s-article-gallery-card:hover {
  text-decoration: none;
}

.s-article-gallery-card:hover:after {
  opacity: 1;
}

.s-article__content {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 70px 100px 200px 100px;
  border-radius: 0 0 5px 5px;
  background: #ffffff;
}

.s-article__content p {
  display: block;
  margin: 0 0 30px 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-article__content a {
  color: #d32231;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s ease-out;
}

.s-article__content a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-article__content h1,
.s-article__content h2,
.s-article__content h3,
.s-article__content h4,
.s-article__content h5,
.s-article__content h6 {
  display: block;
  margin: 0 0 20px 0;
  color: #000000;
  font-weight: 700;
}

.s-article__content h1 {
  font-size: 50px;
}

.s-article__content h2 {
  font-size: 30px;
}

.s-article__content h3 {
  font-size: 25px;
}

.s-article__content h4 {
  font-size: 20px;
}

.s-article__content h5 {
  font-size: 16px;
}

.s-article__content h6 {
  font-size: 12px;
}

.s-article__content ul {
  display: block;
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

.s-article__content ul li {
  display: block;
  position: relative;
  padding: 0 0 25px 45px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-article__content ul li:before {
  display: block;
  position: absolute;
  content: "";
  top: 8px;
  left: 27px;
  width: 8px;
  height: 8px;
  border-radius: 999rem;
  background-color: #d32231;
}

.s-article__content blockquote {
  display: block;
  margin: 0 0 30px 0;
  padding: 40px 55px;
  border-left: 4px solid #d32231;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
}

.s-article .table {
  margin: 0 0 30px 0;
}

.s-article .table td,
.s-article .table th {
  padding: 15px 20px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}

.s-article .table-bordered td,
.s-article .table-bordered th {
  border-color: #a1a1a1;
}

.s-article .table thead th {
  padding: 10px 20px;
  border: 1px solid #9b111d;
  background-color: #d32231;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.s-article .table thead th {
  vertical-align: middle;
}

.s-contacts {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 0 100px 0;
}

.s-contacts__title {
  display: inline-block;
  margin: 0 145px 20px 0;
  color: #000000;
  font-size: 50px;
  font-weight: 700;
}

.s-contacts__what {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 30px;
  font-weight: 400;
}

.s-contacts__subtitle {
  display: block;
  margin: 45px 0 10px 0;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.s-contacts__info {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
}

.s-contacts__info a {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-contacts__info a:hover {
  color: #d32231;
  text-decoration: none;
}

.s-contacts__link {
  display: flex;
  margin: 35px 0 0 0;
  align-items: center;
  color: #d32231;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-contacts__link img,
.s-contacts__link svg {
  display: block;
  max-width: 8px;
  max-height: 13px;
  margin-left: 15px;
  transition: all 0.2s ease-out;
}

.s-contacts__link:hover {
  color: #d32231;
  text-decoration: none;
}

.s-contacts__link:hover img,
.s-contacts__link:hover svg {
  transform: translateX(5px);
}

.s-contacts__button {
  display: inline-block;
  margin: 70px 0 20px 0;
  border-bottom: 1px dashed #d32231;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.s-contacts__button:hover {
  color: #d32231;
  text-decoration: none;
}

.s-map {
  display: block;
  position: relative;
  padding: 100px 0 20px 0;
  background: #ffffff;
}

.s-map__title {
  display: block;
  margin: 0 0 38px 0;
  color: #000000;
  font-size: 30px;
  font-weight: 700;
}

.s-map #map {
  display: block;
  position: relative;
  width: 100%;
  height: 500px;
}

.s-qa {
  display: block;
  position: relative;
  padding: 80px 0;
}

.s-qa__image {
  display: block;
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 160px 0 0 160px;
  transform-style: preserve-3d;
}

.s-qa__image img,
.s-qa__image svg {
  display: block;
  position: relative;
  z-index: 2;
}

.s-qa__1 {
  display: block;
  position: absolute;
  top: -140px;
  left: -160px;
  width: 368px;
  height: 369px;
  background: url("../images/circle_1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 10s linear infinite;
}

.s-qa__2 {
  display: block;
  position: absolute;
  top: -150px;
  left: -120px;
  width: 290px;
  height: 383px;
  background: url("../images/circle_2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: reverseRotateAnimation 15s linear infinite;
}

.s-qa__3 {
  display: block;
  position: absolute;
  top: -130px;
  left: -145px;
  width: 340px;
  height: 341px;
  background: url("../images/circle_3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
  transition: all 0.2s ease-out;
  animation: rotateAnimation 20s linear infinite;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .s-header__mob {
    display: block;
  }
  .s-header {
    padding: 8px 0;
  }
  .s-header__info {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .s-header__button {
    display: block;
    width: 100%;
    margin: 10px 0 20px 0;
  }
  .s-header__nav > li > a {
    padding: 6px 0;
  }
  .s-header__search {
    margin-top: 20px;
  }
  .s-header__row {
    display: none;
  }
  .s-header__search input {
    max-width: 100%;
  }
  .s-modal__text {
    margin-bottom: 20px;
  }
  .s-home-top {
    padding: 40px 0;
  }
  .s-home-top__title {
    margin: 0;
    font-size: 28px;
  }
  .s-home-top__text {
    margin: 20px 0;
  }
  .s-home-top__image img,
  .s-home-top__image svg {
    max-width: 100%;
    margin: 20px 0 0 0;
  }
  .s-home-top__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-top__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-top__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-cards-card__content {
    padding: 10px;
  }
  .s-home-cards-card {
    margin-bottom: 10px;
  }
  .s-list {
    margin: 20px 0 0 0;
  }
  .s-home-what {
    margin: 0;
    padding: 20px 0;
  }
  .s-home-what__title {
    margin: 0 0 20px 0;
    font-size: 42px;
  }
  .s-home-what__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-what__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-what__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-about {
    padding: 30px 0;
  }
  .s-home-about-card {
    padding: 30px 0 0 0;
  }
  .s-home-where {
    padding: 30px 0;
  }
  .s-home-where-card {
    margin: 30px 0 0 0;
  }
  .s-home-projects {
    padding: 30px 0;
  }
  .s-home-projects__link {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  .s-home-projects__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-home-map {
    padding: 30px 0;
  }
  .s-home-map__map {
    margin: 10px auto;
  }
  .s-home-news {
    padding: 30px 0;
  }
  .s-home-news__title {
    width: 100%;
  }
  .s-home-news__link {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  .s-home-news__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-home-seo {
    padding: 30px 0;
  }
  .s-footer {
    padding: 30px 0;
  }
  .s-footer__link {
    margin: 20px 0;
  }
  .s-home-top__button {
    margin: 20px 0;
  }
  .s-press__title {
    font-size: 32px;
  }
  .s-press {
    padding: 0;
  }
  .s-smi {
    padding: 20px 0;
  }
  .s-smi {
    padding: 30px 0;
  }
  .s-smi__title {
    width: 100%;
  }
  .s-smi__link {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  .s-smi__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-about__image {
    margin: 10px 0;
  }
  .s-home-about__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-about__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-about__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-about__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-about__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-about__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-wow {
    padding: 30px 0;
  }
  .s-wow__text {
    margin: 20px 0 0 0;
    font-size: 32px;
  }
  .s-wow__title {
    margin: 0 0 20px 0;
  }
  .s-history {
    padding: 30px 0;
  }
  .s-history__thumbs .swiper-slide-active .s-history-thumb__date {
    font-size: 50px;
  }
  .s-history__thumbs {
    margin: 10px 30px 0 30px;
  }
  .s-history-thumb__title {
    margin-left: 10px;
  }
  .s-history .swiper-button-next,
  .s-history .swiper-button-prev {
    top: 45px;
  }
  .s-history__top {
    padding-top: 20px;
  }
  .s-history-card__image {
    height: 250px;
  }
  .s-history-card__text {
    margin: 15px 0;
  }
  .s-history-card__text {
    max-height: 216px;
    overflow: hidden;
  }
  .s-features__image {
    max-width: 100%;
    margin: 20px auto;
  }
  .s-features {
    padding: 30px 0;
  }
  .s-features-card {
    margin-bottom: 30px;
  }
  .s-features__title {
    margin-bottom: 30px;
  }
  .s-papers {
    padding: 30px 0;
  }
  .s-papers {
    padding: 30px 0;
  }
  .s-papers__title {
    width: 100%;
  }
  .s-papers__link {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  .s-papers__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-papers-card {
    margin: 0 auto;
  }
  .gallery-top .swiper-slide {
    height: 300px;
  }
  .gallery-thumbs .swiper-slide {
    height: 80px;
  }
  .s-good__title {
    margin: 20px 0 0 0;
    font-size: 32px;
  }
  .s-good__button {
    margin: 20px 0;
  }
  .s-tabs {
    padding: 30px 0;
  }
  .s-tabs__header li {
    margin: 0 20px 5px 0;
  }
  .s-tabs__content {
    padding: 20px;
  }
  .s-tabs__content p {
    font-size: 14px;
  }
  .s-files-card__title {
    margin-right: 10px;
    font-size: 14px;
  }
  .s-files-card__image {
    margin-right: 10px;
  }
  .s-goods__title {
    font-size: 32px;
  }
  .s-goods-card {
    margin: 0 auto 10px auto;
    padding: 20px;
  }
  .s-page-form {
    padding: 30px 0;
  }
  .s-page-form__info {
    margin: 10px 0;
    text-align: center;
  }
  .s-get {
    padding: 20px 0;
  }
  .s-get-card {
    margin-bottom: 30px;
  }
  .s-get-card__title {
    margin: 15px 0;
  }
  .s-header__nav li:hover > ul {
    display: none;
  }
  .s-article__title {
    font-size: 32px;
  }
  .s-article__big {
    height: 300px;
  }
  .s-article__content {
    padding: 20px;
  }
  .s-article__info {
    margin-bottom: 30px;
  }
  .s-article__content blockquote {
    padding: 20px;
  }
  .s-article .table td,
  .s-article .table th {
    padding: 10px;
  }
  .s-article .table thead th {
    padding: 10px;
  }
  .s-qa__image {
    display: none;
  }
  .s-qa {
    padding: 0;
  }
  .s-modal__content_page .s-modal__info {
    margin: 10px 0;
    text-align: center;
  }
  .s-map #map {
    height: 350px;
  }
  .s-map {
    padding: 30px 0;
  }
  .s-contacts {
    padding: 0 0 30px 0;
  }
  .s-contacts__subtitle {
    margin-top: 30px;
  }
  .s-home-top__title {
    font-size: 24px;
  }
  .s-home-top__text {
    font-size: 18px;
  }
  .s-home-what__image img,
  .s-home-what__image svg {
    max-width: 100%;
  }
  .s-modal__close img,
  .s-modal__close svg {
    max-width: 28px;
  }
  .s-about__title {
    font-size: 42px;
  }
  .s-history-thumb {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  .s-history__wrap:before {
    width: 50px;
  }
  .s-history__wrap:after {
    width: 50px;
  }
  .s-home-top__image img,
  .s-home-top__image svg {
    display: block;
    margin: 20px auto 0 auto;
  }
  .s-about__image img,
  .s-about__image svg {
    display: block;
    margin: 0 auto;
  }
  .s-good__row {
    align-items: center;
  }
  .s-good__button {
    margin-right: 10px;
  }
  .s-good__content {
    min-height: auto;
  }
  .s-home-what__title {
    margin-bottom: 35px;
  }
}
