.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

.s-header {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0 10px 0;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 7;
  &__mob {
    display: none;
    position: absolute;
    right: 15px;
    top: 0;
    padding: 5px 0 20px 20px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__logo {
    display: block;
    img,
    svg {
      max-width: 100%;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 10px 0 20px 0;
  }
  &__info {
    display: inline-flex;
    align-items: center;
    position: relative;
    max-width: 180px;
    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-right: 14px;
      border-radius: 999rem;
      background-color: #f5f5f5;
      img,
      svg {
        display: block;
        max-width: 20px;
        max-height: 20px;
      }
    }
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    a {
      color: #000000;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d32231;
        text-decoration: none;
      }
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    padding: 6px 11px;
    border-radius: 5px;
    border: 1px solid #d32231;
    background-color: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #d32231;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__lang {
    display: inline-block;
    margin: 0 0 0 8px;
    input {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      &:checked ~ div {
        color: #d32231;
      }
    }
    & > div {
      display: block;
      color: #919191;
      font-size: 15px;
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d32231;
        text-decoration: none;
      }
    }
  }
  &__nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    ul {
      display: none;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li:hover {
      & > ul {
        display: block;
      }
    }
    & > li {
      position: relative;
      &:hover {
        & > a {
          border-color: #d32231;
        }
      }
      & > a {
        display: inline-block;
        position: relative;
        margin: 0 100px 0 0;
        padding: 6px 0 2px 0;
        border-bottom: 3px solid transparent;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
          border-color: #d32231;
          color: #000000;
          text-decoration: none;
        }
      }
      & > ul {
        position: absolute;
        top: 100%;
        left: -28px;
        width: 340px;
        padding: 20px 0;
        border-radius: 5px;
        background-color: #373737;
        z-index: 8;
        & > li {
          display: block;
          &:hover {
            & > a {
              background: #212121;
            }
          }
          & > a {
            display: block;
            position: relative;
            padding: 10px 28px;
            color: #ffffff;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
            transition: all 0.2s ease-out;
            &:hover {
              background-color: #212121;
              color: #ffffff;
              text-decoration: none;
            }
          }
          & > ul {
            position: absolute;
            left: 100%;
            top: 0;
            width: 340px;
            padding: 20px 0;
            border-radius: 5px;
            background-color: #212121;
            z-index: 9;
            li {
              display: block;
              position: relative;
              a {
                display: block;
                position: relative;
                padding: 12px 28px 12px 40px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;
                text-decoration: none;
                transition: all 0.2s ease-out;
                &:hover {
                  color: #d32231;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
  &__search {
    display: block;
    position: relative;
    input {
      width: 100%;
      max-width: 205px;
      padding: 6px 30px 6px 10px;
      border: 0;
      outline: none;
      border-radius: 5px;
      background-color: #f5f5f5;
      color: #212121;
      font-size: 14px;
      font-weight: 400;
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 100%;
      border: 0;
      outline: none;
      background: transparent;
      img,
      svg {
        display: block;
        max-width: 20px;
        max-height: 20px;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

@keyframes reverseRotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

.s-home-top {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 130px 0 10px 0;
  background: #ffffff;
  &__title {
    display: block;
    max-width: 620px;
    margin: 40px 0 0 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &__text {
    display: block;
    max-width: 410px;
    margin: 40px 0 0 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  &__button {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 75px 0 0 0;
    padding: 16px 40px 16px 45px;
    border-radius: 5px;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 12px;
      filter: brightness(0) invert(1);
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__image {
    display: block;
    position: relative;
    transform-style: preserve-3d;
    img,
    svg {
      position: relative;
      z-index: 2;
    }
  }
  &__1 {
    display: block;
    position: absolute;
    top: -24px;
    left: 20px;
    width: 507px;
    height: 509px;
    background: url("../images/circle_1.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 10s linear infinite;
  }
  &__2 {
    display: block;
    position: absolute;
    top: -34px;
    left: 75px;
    width: 399px;
    height: 529px;
    background: url("../images/circle_2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: reverseRotateAnimation 15s linear infinite;
  }
  &__3 {
    display: block;
    position: absolute;
    top: -5px;
    left: 20px;
    width: 467px;
    height: 471px;
    background: url("../images/circle_3.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 20s linear infinite;
  }
}

.s-home-gray {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

.s-home-cards {
  display: block;
  position: relative;
  padding: 30px 0;
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 570px;
    overflow: hidden;
    margin: 0 auto 30px auto;
    border-radius: 5px;
    background-color: #ffffff;
    background-position: right bottom;
    background-repeat: no-repeat;
    &:after {
      display: block;
      position: absolute;
      content: "";
      left: -260px;
      bottom: 0;
      width: 260px;
      height: 4px;
      background-color: #d32231;
      z-index: 1;
      transition: all 0.2s ease-out;
    }
    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      width: 100%;
      min-height: 270px;
      padding: 45px 40px 35px 40px;
    }
    &__title {
      display: block;
      max-width: 275px;
      margin: 0;
      color: #000000;
      font-size: 22px;
      font-weight: 700;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 13px;
        max-height: 13px;
        margin-left: 12px;
        transition: all 0.2s ease-out;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        left: 0;
        width: 100%;
      }
      .s-home-cards-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
      text-decoration: none;
    }
  }
}

.s-home-form {
  display: block;
  position: relative;
  padding: 20px 0;
  &__title {
    display: block;
    margin: 0 0 10px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__text {
    display: block;
    margin: 0 0 30px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  input {
    display: block;
    width: 100%;
    max-width: 420px;
    margin: 0 0 30px 0;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    outline: none;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: #d32231;
    }
  }
  &__info {
    display: block;
    margin: 26px 0 0 0;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 420px;
    margin: 0;
    padding: 16px;
    border: 0;
    outline: none;
    border-radius: 5px;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 12px;
      filter: brightness(0) invert(1);
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.s-list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  img,
  svg {
    display: block;
    max-width: 50px;
    max-height: 50px;
  }
  &__title {
    display: block;
    margin: 26px 0 30px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    color: #d32231;
    font-size: 14px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 12px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: translateX(5px);
      }
      /* opacity: 0.7; */
      color: #d32231;
      text-decoration: none;
    }
  }
}

.s-home-what {
  display: block;
  position: relative;
  margin-top: -120px;
  pointer-events: none;
  &__title {
    display: block;
    margin: 320px 0 0 0;
    color: #000000;
    font-size: 80px;
    font-weight: 200;
    text-transform: uppercase;
  }
  &__image {
    display: block;
    position: relative;
    width: 100%;
    transform-style: preserve-3d;
    img,
    svg {
      display: block;
      position: relative;
      max-width: 345px;
      margin: 0 auto;
      z-index: 2;
    }
  }
  &__1 {
    display: block;
    position: absolute;
    top: -164px;
    left: -30px;
    width: 657px;
    height: 658px;
    background: url("../images/circle_1.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 10s linear infinite;
  }
  &__2 {
    display: block;
    position: absolute;
    top: -174px;
    left: 25px;
    width: 515px;
    height: 684px;
    background: url("../images/circle_2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: reverseRotateAnimation 15s linear infinite;
  }
  &__3 {
    display: block;
    position: absolute;
    top: -135px;
    left: -30px;
    width: 605px;
    height: 608px;
    background: url("../images/circle_3.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 20s linear infinite;
  }
}

.s-home-about {
  display: block;
  position: relative;
  padding: 80px 0 50px 0;
  &__title {
    display: block;
    margin: 0 0 35px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__content {
    display: block;
    position: relative;
    max-width: 630px;
    p {
      display: block;
      margin: 0 0 25px 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  &-card {
    display: block;
    position: relative;
    margin: 0;
    padding: 35px 0 45px 0;
    &__number {
      display: block;
      margin: 0 0 5px 0;
      color: #d32231;
      font-size: 70px;
      font-weight: 200;
    }
    &__text {
      display: block;
      margin: 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
    }
  }
}

.s-home-wins {
  display: block;
  position: relative;
  padding: 35px 0;
  background-color: #d32231;
  &-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 310px;
    min-height: 130px;
    margin: 0 auto;
    padding: 2px 35px;
    background: url("../images/wins_bg.png") no-repeat center center;
    background-size: contain;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transform-style: preserve-3d;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 130px;
      height: 130px;
      margin-top: -65px;
      margin-left: -65px;
      border-radius: 999rem;
      background-color: #ba1523;
      transform: translateZ(-1px) scale(0);
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      &:after {
        transform: translateZ(-1px) scale(1);
      }
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.s-home-where {
  display: block;
  position: relative;
  padding: 100px 0;
  background-color: #ffffff;
  &__title {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 270px;
    margin: 80px 0 0 0;
    img,
    svg {
      display: block;
      max-width: 55px;
      max-height: 55px;
    }
    color: #000000;
    font-size: 16px;
    font-weight: 700;
  }
}

.s-home-projects {
  display: block;
  position: relative;
  padding: 90px 0;
  background-color: #f5f5f5;
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
  }
  &__title {
    display: inline-block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__link {
    display: inline-block;
    margin: 0 0 0 35px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 570px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.9);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      width: 100%;
      min-height: 350px;
      padding: 40px 40px 35px 40px;
      border-radius: 5px;
      background-size: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
      background-position: 0%;
      transition: all 0.2s ease-out;
    }
    &__what {
      display: block;
      margin: 0 0 10px 0;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
    &__title {
      display: block;
      max-width: 330px;
      margin: 0;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 20px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 15px;
        max-height: 15px;
        margin-left: 20px;
        transition: all 0.5s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 0.7;
        color: #d32231;
        text-decoration: none;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease-out;
    &:hover {
      .s-home-projects-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      .s-home-projects-card__content {
        /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%); */
        background-position: 0%;
        background-size: 300%;
      }
      background-size: 105% auto;
      text-decoration: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 50px;
    height: 50px;
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 999rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #d32231;
      text-decoration: none;
    }
  }
  .swiper-button-next {
    margin-left: 20px;
  }
}

.s-home-map {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0 75px 0;
  background-color: #f5f5f5;
  &__title {
    display: block;
    position: relative;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__map {
    display: block;
    position: relative;
    max-width: 100%;
    margin: -40px auto 0 auto;
  }
}

.s-home-news {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 90px 0;
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
  }
  &__title {
    display: inline-block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__link {
    display: inline-block;
    margin: 0 0 0 35px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 270px;
    border-radius: 5px;
    &__image {
      display: block;
      position: relative;
      width: 100%;
      height: 170px;
      overflow: hidden;
      border-radius: 5px;
      background-color: #878787;
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 0;
        left: -100%;
        width: 128px;
        height: 100%;
        background: url("../images/news_hover.png") no-repeat left center;
        background-size: auto 100%;
        transition: all 0.4s ease-out;
      }
    }
    &__title {
      display: block;
      height: 95px;
      overflow: hidden;
      margin: 15px 0 10px 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 13px;
        max-height: 13px;
        margin-left: 10px;
        transition: all 0.2s ease-out;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .s-home-projects-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      .s-home-projects-card__content {
        background: rgba(0, 0, 0, 0.7);
      }
      .s-home-news-card__image:after {
        left: 0;
      }
      text-decoration: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 50px;
    height: 50px;
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 999rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #d32231;
      text-decoration: none;
    }
  }
  .swiper-button-next {
    margin-left: 20px;
  }
  &__image {
    display: block;
    position: relative;
    left: -300px;
    bottom: -300px;
    width: 100%;
    transform-style: preserve-3d;
    img,
    svg {
      display: block;
      position: relative;
      max-width: 345px;
      margin: 0 auto;
      z-index: 2;
    }
    &_page {
      position: absolute;
      left: auto;
      bottom: 30px;
      left: 85%;
    }
    &_article {
      position: absolute;
      bottom: -20px;
      left: calc(50% - 300px);
    }
  }
  &__1 {
    display: block;
    position: absolute;
    top: -164px;
    left: -30px;
    width: 657px;
    height: 658px;
    background: url("../images/circle_1.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 10s linear infinite;
  }
  &__2 {
    display: block;
    position: absolute;
    top: -174px;
    left: 25px;
    width: 515px;
    height: 684px;
    background: url("../images/circle_2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: reverseRotateAnimation 15s linear infinite;
  }
  &__3 {
    display: block;
    position: absolute;
    top: -135px;
    left: -30px;
    width: 605px;
    height: 608px;
    background: url("../images/circle_3.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 20s linear infinite;
  }
}

.s-smi {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0 80px 0;
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
  }
  &__title {
    display: inline-block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__link {
    display: inline-block;
    margin: 0 0 0 35px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 270px;
    border-radius: 5px;
    &__image {
      display: block;
      position: relative;
      width: 100%;
      height: 170px;
      overflow: hidden;
      border-radius: 5px;
      background-color: #878787;
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 0;
        left: -100%;
        width: 128px;
        height: 100%;
        background: url("../images/news_hover.png") no-repeat left center;
        background-size: auto 100%;
        transition: all 0.4s ease-out;
      }
    }
    &__title {
      display: block;
      height: 95px;
      overflow: hidden;
      margin: 15px 0 10px 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 13px;
        max-height: 13px;
        margin-left: 10px;
        transition: all 0.2s ease-out;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .s-smi-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      .s-smi-card__content {
        background: rgba(0, 0, 0, 0.7);
      }
      .s-smi-card__image:after {
        left: 0;
      }
      text-decoration: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 50px;
    height: 50px;
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 999rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #d32231;
      text-decoration: none;
    }
  }
  .swiper-button-next {
    margin-left: 20px;
  }
  &__image {
    display: block;
    position: relative;
    left: -300px;
    bottom: -300px;
    width: 100%;
    transform-style: preserve-3d;
    img,
    svg {
      display: block;
      position: relative;
      max-width: 345px;
      margin: 0 auto;
      z-index: 2;
    }
  }
}

.s-home-seo {
  display: block;
  position: relative;
  padding: 90px 0 40px 0;
  background-color: #f5f5f5;
  &_hide {
    max-height: 100% !important;
  }
  &__title {
    display: block;
    margin: 0 0 36px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__content {
    display: block;
    position: relative;
    max-height: 96px;
    overflow: hidden;
    p {
      display: block;
      margin: 0 0 30px 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  &__more {
    display: flex;
    align-items: center;
    position: relative;
    margin: 25px 0 0 0;
    color: #d32231;
    font-size: 14px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 14px;
      max-height: 14px;
      margin-left: 14px;
      transition: all 0.2s ease-out;
    }
    &_active {
      img,
      svg {
        transform: rotate(180deg);
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
}

.s-footer {
  display: block;
  position: relative;
  width: 100%;
  padding: 100px 0 50px 0;
  background-color: #f5f5f5;
  &__logo {
    display: block;
    img,
    svg {
      max-width: 100%;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__what {
    display: block;
    margin: 30px 0 25px 0;
    color: #8d8d8d;
    font-size: 16px;
    font-weight: 400;
  }
  &__button {
    display: inline-block;
    position: relative;
    padding: 6px 11px;
    border-radius: 5px;
    border: 1px solid #d32231;
    background-color: transparent;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #d32231;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__phone {
    display: block;
    margin: 0 0 15px 0;
    color: #000000;
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    margin: 74px 0 0 0;
    color: #d32231;
    font-size: 14px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 10px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: translateX(5px);
      }
      color: #d32231;
      text-decoration: none;
    }
  }
  &__subtitle {
    display: block;
    margin: 0 0 20px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__nav {
    display: block;
    margin: 0;
    padding: 0 0 20px 0;
    list-style: none;
    li {
      display: block;
      a {
        display: block;
        margin: 0 0 11px 0;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
          color: #d32231;
          text-decoration: none;
        }
      }
    }
  }
}

.s-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
  &__centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: #ffffff;
  }
  &__close {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
      text-decoration: none;
    }
  }
  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 630px;
    margin: 20px 0;
    padding: 20px;
    background: transparent;
    &_page {
      align-items: flex-start;
      max-width: 100%;
      padding: 0;
      .s-modal__button {
        display: flex;
        width: 100%;
        max-width: 100%;
        padding: 15px;
      }
      .s-modal__title {
        text-align: left;
      }
      .s-modal__text {
        text-align: left;
      }
      .s-modal__info {
        margin: 0;
        font-size: 12px;
        text-align: left;
      }
      .s-modal__file > span {
        background-position: 0 center;
      }
      input {
        margin-bottom: 22px;
      }
    }
  }
  &__title {
    display: block;
    margin: 0 0 10px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  &__text {
    display: block;
    margin: 0 0 45px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
    text-align: center;
  }
  &__input {
    display: block;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    outline: none;
    background-color: #f5f5f5;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: #d32231;
    }
  }
  &__file {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 0 35px 0;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      opacity: 0;
    }
    & > span {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 25px;
      padding-left: 47px;
      background: url("../images/icon_file_upload.png") no-repeat left center;
      background-size: contain;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
  &__info {
    display: block;
    margin: 15px 0 0 0;
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 16px 64px;
    border: 0;
    outline: none;
    border-radius: 5px;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 12px;
      filter: brightness(0) invert(1);
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.s-breadcrumps {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 30px 0;
  list-style: none;
  li {
    display: inline-block;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    img,
    svg {
      display: inline-block;
      margin: 0 10px;
      max-width: 6px;
    }
    a {
      color: #8d8d8d;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d32231;
        text-decoration: none;
      }
    }
  }
}

.s-press {
  display: block;
  position: relative;
  padding: 0 0 30px 0;
  &__title {
    display: block;
    margin: 0 0 30px 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &__cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    width: 100%;
  }
  &-card {
    display: inline-block;
    margin: 0 20px 20px 0;
    padding: 12px 26px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: #d32231;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.s-about {
  display: block;
  position: relative;
  padding: 0;
  &__title {
    display: block;
    margin: 0 0 30px 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &__name {
    display: block;
    margin: 0 0 5px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__who {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
  &__content {
    display: block;
    max-width: 580px;
    p {
      display: block;
      margin: 30px 0 0 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  &__image {
    display: block;
    position: relative;
    transform-style: preserve-3d;
    img,
    svg {
      position: relative;
      max-width: 100%;
      z-index: 2;
    }
  }
  &__1 {
    display: block;
    position: absolute;
    top: -30px;
    left: -30px;
    width: 485px;
    height: 485px;
    background: url("../images/circle_1.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    /* animation: rotateAnimation 10s linear infinite; */
  }
  &__2 {
    display: block;
    position: absolute;
    top: -40px;
    left: 20px;
    width: 381px;
    height: 505px;
    background: url("../images/circle_2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    /* animation: reverseRotateAnimation 15s linear infinite; */
  }
  &__3 {
    display: block;
    position: absolute;
    top: -15px;
    left: -15px;
    width: 447px;
    height: 449px;
    background: url("../images/circle_3.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    /* animation: rotateAnimation 20s linear infinite; */
  }
}

.s-wow {
  display: block;
  position: relative;
  padding: 55px 0 20px 0;
  &__title {
    display: block;
    margin: 0 0 45px 0;
    color: #d32231;
    font-size: 30px;
    font-weight: 400;
  }
  &__image {
    display: block;
    max-width: 100%;
  }
  &__text {
    display: block;
    margin: 120px 0 0 0;
    color: #000000;
    font-size: 80px;
    font-weight: 200;
    text-transform: uppercase;
  }
}

.s-history {
  display: block;
  position: relative;
  width: 100%;
  padding: 100px 0;
  &__wrap {
    display: block;
    position: relative;
    width: 100%;
    max-width: 980px;
    overflow: hidden;
    background: url("../images/line_red.png") no-repeat center bottom;
    background-size: 100% 2px;
    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 75px;
      height: 100%;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to left, rgba(245, 245, 245, 0), rgba(245, 245, 245, 1));
      z-index: 4;
      pointer-events: none;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 75px;
      height: 100%;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to right, rgba(245, 245, 245, 0), rgba(245, 245, 245, 1));
      z-index: 4;
      pointer-events: none;
    }
  }
  &__title {
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__thumbs {
    display: block;
    position: relative;
    width: 100%;
    max-width: 530px;
    overflow: visible !important;
    margin: 0 0 0 80px;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #d32231;
    .swiper-wrapper {
      display: flex;
      align-items: flex-end;
    }
    .swiper-slide-active {
      .s-history-thumb {
        max-width: 500px;
      }
      .s-history-thumb__date {
        font-size: 70px;
        font-weight: 200;
      }
      .s-history-thumb__title {
        max-width: 175px;
      }
    }
  }
  &__top {
    display: block;
    position: relative;
    width: 100%;
    padding: 55px 0 0 0;
  }
  &-thumb {
    display: inline-flex;
    align-items: flex-end;
    position: relative;
    width: auto;
    max-width: 180px;
    &__date {
      display: block;
      color: #d32231;
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
    }
    &__title {
      display: block;
      max-width: 0;
      overflow: hidden;
      margin: 0 0 0 28px;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
    }
    &:before {
      display: block;
      position: absolute;
      content: "";
      left: -20px;
      bottom: -10px;
      width: 2px;
      height: 10px;
      background-color: #d32231;
    }
  }
  &-card {
    display: block;
    position: relative;
    max-width: 100%;
    &__image {
      display: block;
      width: 100%;
      max-width: 570px;
      height: 350px;
      background-position: center center;
      background-size: cover;
    }
    &__text {
      display: block;
      max-height: 350px;
      overflow: hidden;
      margin: 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 110px;
    right: 0;
    bottom: auto;
    left: auto;
    width: 50px;
    height: 50px;
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 999rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #d32231;
      text-decoration: none;
    }
  }
  .swiper-button-prev {
    right: 70px;
  }
}

.s-features {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 100px 0 50px 0;
  background-color: #1f1f1f;
  &__image {
    display: block;
    position: relative;
    margin-left: -380px;
  }
  &__title {
    display: block;
    margin: 0 0 60px 0;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 270px;
    margin: 0 0 50px 0;
    img,
    svg {
      display: block;
      max-width: 100%;
    }
    &__title {
      display: block;
      position: relative;
      margin: 0 0 0 -20px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.s-papers {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
  background: #f5f5f5;
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
  }
  &__title {
    display: inline-block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__link {
    display: inline-block;
    margin: 0 0 0 35px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 170px;
    padding: 5px;
    border-radius: 5px;
    background-color: #ffffff;
    &__image {
      display: block;
      width: 100%;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;
    }
    &__content {
      display: block;
      width: 100%;
      height: 235px;
      border-radius: 5px;
      background-color: rgba(211, 34, 49, 0.8);
      opacity: 0;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .s-papers-card__content {
        opacity: 1;
      }
      text-decoration: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    width: 50px;
    height: 50px;
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 999rem;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 20px;
      max-height: 20px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #d32231;
      text-decoration: none;
    }
  }
  .swiper-button-next {
    margin-left: 20px;
  }
}

.s-good {
  display: block;
  position: relative;
  width: 100%;
  &__content {
    display: block;
    position: relative;
    min-height: 402px;
    p {
      display: block;
      margin: 30px 0 0 0;
      color: #8d8d8d;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  &__title {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 35px;
    font-weight: 700;
  }
  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
  &__button {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0 30px 0 0;
    padding: 15px 26px;
    border-radius: 5px;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 8px;
      max-height: 13px;
      margin-left: 12px;
      filter: brightness(0) invert(1);
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__button_alt {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 12px 16px;
    border-radius: 5px;
    border: 1px solid #d32231;
    outline: none;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 26px;
      max-height: 26px;
      margin-right: 20px;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      border-color: #ff6774;
      background-color: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.gallery-top {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  background: #ffffff;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 340px;
    padding: 10px;
    img,
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.gallery-thumbs {
  display: block;
  position: relative;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 0 10px 10px 10px;
  background: #ffffff;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 105px;
    border-radius: 3px;
    border: 1px solid transparent;
    background-color: #ffffff;
    img,
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
  .swiper-slide-thumb-active {
    border-color: #d32231 !important;
  }
}

.s-tabs {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0;
  &__header {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 3;
    li {
      display: inline-block;
      margin-right: 45px;
      margin-bottom: -4px;
      padding: 5px 0;
      border-bottom: 4px solid transparent;
      color: #8d8d8d;
      font-size: 16px;
      font-weight: 600;
      &.current {
        border-color: #d32231;
        color: #000000;
        font-weight: 600;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__title {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__content {
    display: none;
    position: relative;
    width: 100%;
    min-height: 504px;
    margin: 0;
    padding: 80px;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    p {
      margin: 20px 0 0 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
    &.current {
      display: block;
    }
  }
}

.s-files {
  display: block;
  position: relative;
  width: 100%;
  padding: 30px 0;
  &__title {
    display: block;
    margin: 0 0 36px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &-card {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    &__image {
      display: block;
      max-width: 24px;
      max-height: 30px;
      margin: 0 24px 0 0;
      filter: brightness(0);
      opacity: 0.2;
      transition: all 0.2s ease-out;
    }
    &__title {
      display: block;
      flex: 1 1 auto;
      margin: 0;
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
      transition: all 0.2s ease-out;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 8px;
        max-height: 13px;
        margin-left: 9px;
        transition: all 0.2s ease-out;
      }
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .s-files-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      .s-files-card__title {
        color: #d32231;
      }
      .s-files-card__image {
        opacity: 1;
        filter: none;
      }
      text-decoration: none;
    }
  }
}

.s-goods {
  display: block;
  position: relative;
  width: 100%;
  &__title {
    display: block;
    margin: 0 0 30px 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &-aside {
    display: block;
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li {
      display: block;
    }
    &_active {
      .s-goods-aside-card {
        img,
        svg {
          filter: none;
          opacity: 1;
        }
        border-color: #d32231;
        color: #000000;
        text-decoration: none;
      }
    }
    &-card {
      display: block;
      position: relative;
      width: 100%;
      margin: 0 auto 10px auto;
      padding: 20px 28px;
      min-height: 70px;
      border-bottom: 4px solid transparent;
      border-radius: 5px;
      background-color: #ffffff;
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      img,
      svg {
        display: block;
        max-width: 32px;
        max-height: 32px;
        margin: 0 auto;
        filter: brightness(0);
        opacity: 0.2;
        transition: all 0.2s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        img,
        svg {
          filter: none;
          opacity: 1;
        }
        border-color: #d32231;
        color: #000000;
        text-decoration: none;
      }
    }
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 770px;
    overflow: hidden;
    margin: 0 auto 30px auto;
    padding: 40px 40px 35px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    &:after {
      display: block;
      position: absolute;
      content: "";
      left: -100%;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: #d32231;
      z-index: 1;
      transition: all 0.2s ease-out;
    }
    img,
    svg {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
    &__content {
      display: block;
      position: relative;
      width: 100%;
      min-height: 174px;
      p {
        display: block;
        margin: 10px 0 0 0;
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__title {
      display: block;
      margin: 0;
      color: #000000;
      font-size: 22px;
      font-weight: 700;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 13px;
        max-height: 13px;
        margin-left: 12px;
        transition: all 0.2s ease-out;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .s-goods-card__link {
        img,
        svg {
          transform: translateX(5px);
        }
      }
      &:after {
        left: 0;
      }
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
      text-decoration: none;
    }
  }
}

.s-page-form {
  display: block;
  position: relative;
  padding: 80px 0;
  &__title {
    display: block;
    margin: 0 0 10px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  &__text {
    display: block;
    margin: 0 0 30px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  input {
    display: block;
    width: 100%;
    margin: 0 0 23px 0;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    outline: none;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: #d32231;
    }
  }
  &__info {
    display: block;
    margin: 0;
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 420px;
    margin: 0;
    padding: 16px;
    border: 0;
    outline: none;
    border-radius: 5px;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 13px;
      max-height: 13px;
      margin-left: 12px;
      filter: brightness(0) invert(1);
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #ff6774;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.s-get {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 80px 0;
  &-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    img,
    svg {
      display: block;
      max-width: 50px;
      max-height: 50px;
    }
    &__title {
      display: block;
      margin: 26px 0 30px 0;
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      color: #d32231;
      font-size: 14px;
      font-weight: 700;
      img,
      svg {
        display: block;
        max-width: 13px;
        max-height: 13px;
        margin-left: 12px;
        transition: all 0.2s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        .s-get-card__link {
          img,
          svg {
            transform: translateX(5px);
          }
        }
        opacity: 0.7;
        color: #d32231;
        text-decoration: none;
      }
    }
  }
}

.s-article {
  display: block;
  position: relative;
  width: 100%;
  &__title {
    display: block;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &__big {
    display: block;
    width: 100%;
    height: 560px;
    border-radius: 5px 5px 0 0;
    background-position: center center;
    background-size: cover;
  }
  &__info {
    display: block;
    margin: 0 0 55px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
  }
  &-gallery {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
    &-card {
      display: block;
      position: relative;
      width: 100%;
      max-width: 170px;
      height: 170px;
      margin: 0 22px 22px 0;
      border-radius: 5px;
      background-position: center center;
      background-size: cover;
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        opacity: 0;
        background-color: rgba(211, 34, 49, 0.8);
        transition: all 0.2s ease-out;
      }
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        &:after {
          opacity: 1;
        }
        text-decoration: none;
      }
    }
  }
  &__content {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 70px 100px 200px 100px;
    border-radius: 0 0 5px 5px;
    background: #ffffff;
    p {
      display: block;
      margin: 0 0 30px 0;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
    a {
      color: #d32231;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d32231;
        text-decoration: none;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0 0 20px 0;
      color: #000000;
      font-weight: 700;
    }
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 25px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 12px;
    }
    ul {
      display: block;
      position: relative;
      margin: 0 0 30px 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        padding: 0 0 25px 45px;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.5;
        &:before {
          display: block;
          position: absolute;
          content: "";
          top: 8px;
          left: 27px;
          width: 8px;
          height: 8px;
          border-radius: 999rem;
          background-color: #d32231;
        }
      }
    }
    blockquote {
      display: block;
      margin: 0 0 30px 0;
      padding: 40px 55px;
      border-left: 4px solid #d32231;
      border-radius: 5px;
      background-color: #f5f5f5;
      color: #8d8d8d;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
    }
  }
  .table {
    margin: 0 0 30px 0;
  }
  .table td,
  .table th {
    padding: 15px 20px;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
  }
  .table-bordered td,
  .table-bordered th {
    border-color: #a1a1a1;
  }
  .table thead th {
    padding: 10px 20px;
    border: 1px solid #9b111d;
    background-color: #d32231;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }
  .table thead th {
    vertical-align: middle;
  }
}

.s-contacts {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 0 100px 0;
  &__title {
    display: inline-block;
    margin: 0 145px 20px 0;
    color: #000000;
    font-size: 50px;
    font-weight: 700;
  }
  &__what {
    display: inline-block;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-weight: 400;
  }
  &__subtitle {
    display: block;
    margin: 45px 0 10px 0;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }
  &__info {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    a {
      color: #000000;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d32231;
        text-decoration: none;
      }
    }
  }
  &__link {
    display: flex;
    margin: 35px 0 0 0;
    align-items: center;
    color: #d32231;
    font-size: 14px;
    font-weight: 700;
    img,
    svg {
      display: block;
      max-width: 8px;
      max-height: 13px;
      margin-left: 15px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: translateX(5px);
      }
      color: #d32231;
      text-decoration: none;
    }
  }
  &__button {
    display: inline-block;
    margin: 70px 0 20px 0;
    border-bottom: 1px dashed #d32231;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #d32231;
      text-decoration: none;
    }
  }
}

.s-map {
  display: block;
  position: relative;
  padding: 100px 0 20px 0;
  background: #ffffff;
  &__title {
    display: block;
    margin: 0 0 38px 0;
    color: #000000;
    font-size: 30px;
    font-weight: 700;
  }
  #map {
    display: block;
    position: relative;
    width: 100%;
    height: 500px;
  }
}

.s-qa {
  display: block;
  position: relative;
  padding: 80px 0;
  &__image {
    display: block;
    position: relative;
    width: auto;
    max-width: 100%;
    margin: 160px 0 0 160px;
    transform-style: preserve-3d;
    img,
    svg {
      display: block;
      position: relative;
      z-index: 2;
    }
  }
  &__1 {
    display: block;
    position: absolute;
    top: -140px;
    left: -160px;
    width: 368px;
    height: 369px;
    background: url("../images/circle_1.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 10s linear infinite;
  }
  &__2 {
    display: block;
    position: absolute;
    top: -150px;
    left: -120px;
    width: 290px;
    height: 383px;
    background: url("../images/circle_2.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: reverseRotateAnimation 15s linear infinite;
  }
  &__3 {
    display: block;
    position: absolute;
    top: -130px;
    left: -145px;
    width: 340px;
    height: 341px;
    background: url("../images/circle_3.png") no-repeat center center;
    background-size: contain;
    pointer-events: none;
    transition: all 0.2s ease-out;
    animation: rotateAnimation 20s linear infinite;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .s-header__mob {
    display: block;
  }
  .s-header {
    padding: 8px 0;
  }
  .s-header__info {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .s-header__button {
    display: block;
    width: 100%;
    margin: 10px 0 20px 0;
  }
  .s-header__nav > li > a {
    padding: 6px 0;
  }
  .s-header__search {
    margin-top: 20px;
  }
  .s-header__row {
    display: none;
  }
  .s-header__search input {
    max-width: 100%;
  }
  .s-modal__text {
    margin-bottom: 20px;
  }
  .s-home-top {
    padding: 40px 0;
  }
  .s-home-top__title {
    margin: 0;
    font-size: 28px;
  }
  .s-home-top__text {
    margin: 20px 0;
  }
  .s-home-top__image img,
  .s-home-top__image svg {
    max-width: 100%;
    margin: 20px 0 0 0;
  }
  .s-home-top__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-top__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-top__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-cards-card__content {
    padding: 10px;
  }
  .s-home-cards-card {
    margin-bottom: 10px;
  }
  .s-list {
    margin: 20px 0 0 0;
  }
  .s-home-what {
    margin: 0;
    padding: 20px 0;
  }
  .s-home-what__title {
    margin: 0 0 20px 0;
    font-size: 42px;
  }
  .s-home-what__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-what__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-what__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-about {
    padding: 30px 0;
  }
  .s-home-about-card {
    padding: 30px 0 0 0;
  }
  .s-home-where {
    padding: 30px 0;
  }
  .s-home-where-card {
    margin: 30px 0 0 0;
  }
  .s-home-projects {
    padding: 30px 0;
  }
  .s-home-projects__link {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  .s-home-projects__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-home-map {
    padding: 30px 0;
  }
  .s-home-map__map {
    margin: 10px auto;
  }
  .s-home-news {
    padding: 30px 0;
    &__title {
      width: 100%;
    }
    &__link {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
  }
  .s-home-news__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-home-seo {
    padding: 30px 0;
  }
  .s-footer {
    padding: 30px 0;
  }
  .s-footer__link {
    margin: 20px 0;
  }
  .s-home-top__button {
    margin: 20px 0;
  }
  .s-press__title {
    font-size: 32px;
  }
  .s-press {
    padding: 0;
  }
  .s-smi {
    padding: 20px 0;
  }
  .s-smi {
    padding: 30px 0;
    &__title {
      width: 100%;
    }
    &__link {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
  }
  .s-smi__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-about__image {
    margin: 10px 0;
  }
  .s-home-about__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-home-about__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-home-about__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-about__1 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-about__2 {
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
  }
  .s-about__3 {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
  .s-wow {
    padding: 30px 0;
  }
  .s-wow__text {
    margin: 20px 0 0 0;
    font-size: 32px;
  }
  .s-wow__title {
    margin: 0 0 20px 0;
  }
  .s-history {
    padding: 30px 0;
  }
  .s-history__thumbs .swiper-slide-active .s-history-thumb__date {
    font-size: 50px;
  }
  .s-history__thumbs {
    margin: 10px 30px 0 30px;
  }
  .s-history-thumb__title {
    margin-left: 10px;
  }
  .s-history .swiper-button-next,
  .s-history .swiper-button-prev {
    top: 45px;
  }
  .s-history__top {
    padding-top: 20px;
  }
  .s-history-card__image {
    height: 250px;
  }
  .s-history-card__text {
    margin: 15px 0;
  }
  .s-history-card__text {
    max-height: 216px;
    overflow: hidden;
  }
  .s-features__image {
    max-width: 100%;
    margin: 20px auto;
  }
  .s-features {
    padding: 30px 0;
  }
  .s-features-card {
    margin-bottom: 30px;
  }
  .s-features__title {
    margin-bottom: 30px;
  }
  .s-papers {
    padding: 30px 0;
  }
  .s-papers {
    padding: 30px 0;
    &__title {
      width: 100%;
    }
    &__link {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
  }
  .s-papers__row {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .s-papers-card {
    margin: 0 auto;
  }
  .gallery-top .swiper-slide {
    height: 300px;
  }
  .gallery-thumbs .swiper-slide {
    height: 80px;
  }
  .s-good__title {
    margin: 20px 0 0 0;
    font-size: 32px;
  }
  .s-good__button {
    margin: 20px 0;
  }
  .s-tabs {
    padding: 30px 0;
  }
  .s-tabs__header li {
    margin: 0 20px 5px 0;
  }
  .s-tabs__content {
    padding: 20px;
  }
  .s-tabs__content p {
    font-size: 14px;
  }
  .s-files-card__title {
    margin-right: 10px;
    font-size: 14px;
  }
  .s-files-card__image {
    margin-right: 10px;
  }
  .s-goods__title {
    font-size: 32px;
  }
  .s-goods-card {
    margin: 0 auto 10px auto;
    padding: 20px;
  }
  .s-page-form {
    padding: 30px 0;
  }
  .s-page-form__info {
    margin: 10px 0;
    text-align: center;
  }
  .s-get {
    padding: 20px 0;
  }
  .s-get-card {
    margin-bottom: 30px;
  }
  .s-get-card__title {
    margin: 15px 0;
  }
  .s-header__nav li:hover > ul {
    display: none;
  }
  .s-article__title {
    font-size: 32px;
  }
  .s-article__big {
    height: 300px;
  }
  .s-article__content {
    padding: 20px;
  }
  .s-article__info {
    margin-bottom: 30px;
  }
  .s-article__content blockquote {
    padding: 20px;
  }
  .s-article .table td,
  .s-article .table th {
    padding: 10px;
  }
  .s-article .table thead th {
    padding: 10px;
  }
  .s-qa__image {
    display: none;
  }
  .s-qa {
    padding: 0;
  }
  .s-modal__content_page .s-modal__info {
    margin: 10px 0;
    text-align: center;
  }
  .s-map #map {
    height: 350px;
  }
  .s-map {
    padding: 30px 0;
  }
  .s-contacts {
    padding: 0 0 30px 0;
  }
  .s-contacts__subtitle {
    margin-top: 30px;
  }
  .s-home-top__title {
    font-size: 24px;
  }
  .s-home-top__text {
    font-size: 18px;
  }
  .s-home-what__image img,
  .s-home-what__image svg {
    max-width: 100%;
  }
  .s-modal__close {
    img,
    svg {
      max-width: 28px;
    }
  }
  .s-about__title {
    font-size: 42px;
  }
  .s-history-thumb {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  .s-history__wrap:before {
    width: 50px;
  }
  .s-history__wrap:after {
    width: 50px;
  }
  .s-home-top__image img,
  .s-home-top__image svg {
    display: block;
    margin: 20px auto 0 auto;
  }
  .s-about__image img,
  .s-about__image svg {
    display: block;
    margin: 0 auto;
  }
  .s-good__row {
    align-items: center;
  }
  .s-good__button {
    margin-right: 10px;
  }
  .s-good__content {
    min-height: auto;
  }
  .s-home-what__title {
    margin-bottom: 35px;
  }
}
